<template>
	<div>
    <div>
      <Loader v-if="performingRequest" />
       <div class="dashboard__container--body">
       	<UserTable :groupUsers="associatedGroupUsers" action="groupUser" :eventId="eventInfo.id" />
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
import ExportService from "@/services/ExportService"
import router from '@/router'

export default {
  name: 'staffingGroups',
  data() {
    return {
      batch: [],
      showTextBox: false,
      textMessage: '',
      performingRequest: false,
    };
  },

  computed: {
    ...mapState(['eventInfo', 'currentUser', 'userProfile', 'associatedGroupUsers']),
    isJob() {
    	if (this.eventInfo && this.eventInfoshiftType && this.eventInfoshiftType == 'Job') {
    		return true
    	} else {
    		return false
    	}
    }
  },
  created() {
  	this.$store.dispatch("getAssociatedGroupUsers", this.$route.params.id)
  },
  components: {
    Loader,
    UserTable
  },
  // created () {
  //   this.$store.dispatch("getSkills")
  //   this.$store.dispatch("getGroups")
  // },
  methods: {
    async sendTextMessage () {
      this.performingRequest3 = true
      let message = this.textMessage
      await this.batch.forEach(user => {
        console.log(user)
        if (user.phone) {
          let newMessage = {
            userId: user.id,
            phone: user.phone,
            message: message,
          }
          console.log(newMessage)
          this.$store.dispatch('sendUserMessage', newMessage)
        } 
      })
      this.performingRequest3 = false
      this.textMessage = ''
      this.showTextBox = false
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    
    exportSelected() {
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "Points",
        "Rating",
        "Skills",
        "City",
        "State"
      ]
      const exportItems = [];
      for (var key in this.batch) {
        exportItems.push([
          this.batch[key].firstName,
          this.batch[key].lastName,
          this.batch[key].email,
          this.batch[key].phone,
          this.batch[key].points,
          this.batch[key].rating,
          this.batch[key].skills,
          this.batch[key].address.city,
          this.batch[key].address.state,
        ])
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YY')
      } else {
        return null
      }
    },
  },
  beforeDestroy () {
    this.textMessage = ''
    delete this.textMessage
    this.performingRequest = ''
    delete this.performingRequest
    this.$store.dispatch("clearAssociatedGroupUsers")
  }
}
</script>


